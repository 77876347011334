(function ($) {
  $(".logo-carousel__items").slick({
    arrows: false,
    dots: true,
    slidesToShow: 7,
    slidesToScroll: 3,
    //centerMode: true,
    //variableWidth: true,
    autoplay: true,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 5
      }
    }, {
      breakpoint: 900,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 2
      }
    }]
  });
})(jQuery);
const actualBtn = document.getElementById("add-cv");
const fileChosen = document.querySelector(".file-field__info");
const fileWrapper = document.querySelector(".file-field__input");
if (actualBtn) {
  actualBtn.addEventListener("change", function () {
    fileChosen.textContent = this.files[0].name;
    if (actualBtn.files.length > 0) {
      fileWrapper.classList.add("selected"); // Add class when a file is selected
    } else {
      fileWrapper.classList.remove("selected"); // Remove class if no file selected
    }
  });
  document.querySelector(".file-field__remove").addEventListener("click", function () {
    fileWrapper.classList.remove("selected");
    actualBtn.value = "";
    fileChosen.textContent = "upload your C.V";
  });
}
const accordItems = document.getElementsByClassName("verticals-2__accordion-item");
if (accordItems) {
  for (let i = 0; i < accordItems.length; i++) {
    const cardButton = accordItems[i].querySelector("h3");
    cardButton.addEventListener("click", () => {
      if (accordItems[i].classList.contains("active")) {
        accordItems[i].classList.remove("active");
      } else {
        for (let s = 0; s < accordItems.length; s++) {
          accordItems[s].classList.remove("active");
        }
        accordItems[i].classList.add("active");
      }
    });
  }
}
const open_menu = document.getElementById("open-menu");
if (open_menu) {
  open_menu.addEventListener("click", () => {
    document.querySelector(".header").classList.add("mobile-open");
  });
}
const close_menu = document.getElementById("close-menu");
if (close_menu) {
  close_menu.addEventListener("click", () => {
    document.querySelector(".header").classList.remove("mobile-open");
  });
}