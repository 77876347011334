// boton volver arriba
// muestra boton al hacer scroll
window.onscroll = function () {
  scrollFunction();
};
function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    document.getElementById("volver_arriba").style.display = "block";
  } else {
    document.getElementById("volver_arriba").style.display = "none";
  }
}
document.getElementById('volver_arriba').addEventListener('click', function () {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
});