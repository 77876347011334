const postMarkup = data => {
  const {
    title,
    date,
    image_url,
    image_alt,
    permalink
  } = data;
  let markup = `
        <div class="blog-card">
          <div>
            <figure class="blog-card__image">
              <img src="${image_url}" alt="${image_alt}" />
            </figure>
            <div class="blog-card__text">
              <h4>${date}</h4>
              <h3>${title}</h3>
            </div>
          </div>
          <div class="text-right">
              <a href="${permalink}" class="btn">${js_dictionary[0]}</a>
            </div>
        </div>
  `;
  return markup;
};
const posts_container = document.getElementById("posts-container");
const posts_loading = document.getElementsByClassName("blog-grid__loading")[0];

// const handlePostsPaginationClick = (currentPage) => {
//   const pagination_links = document.querySelectorAll(".page-numbers a");
//   for (let p = 0; p < pagination_links.length; p++) {
//     pagination_links[p].addEventListener("click", (event) => {
//       event.preventDefault();
//       if (pagination_links[p].classList.contains("prev")) {
//         loadPosts(currentPage - 1);
//       } else if (pagination_links[p].classList.contains("next")) {
//         loadPosts(currentPage + 1);
//       } else {
//         const this_number = parseInt(pagination_links[p].innerHTML);
//         loadPosts(this_number);
//       }
//     });
//   }
// };

let currentPage = 1;
let posts_per_page = 9;
const load_more_button = document.getElementById("load-more");
function loadPosts(page) {
  jQuery.ajax({
    url: isuzu_vars.ajaxurl,
    type: "post",
    data: {
      action: "isuzu_ajax_getPosts",
      page: page,
      per_page: posts_per_page
    },
    dataType: "json",
    beforeSend: function () {
      //posts_container.innerHTML = "";
      //posts_loading.style.display = "flex";
    },
    success: function (data) {
      if (data && data.length > 0) {
        data.forEach(key => {
          posts_container.insertAdjacentHTML("beforeend", postMarkup(key));
        });
        currentPage++;
      } else {
        //posts_container.innerHTML = `<p class="text-center" style="width: 100%">No posts to show</p>`;
        posts_container.insertAdjacentHTML("beforeend", `<p class="text-center" style="width: 100%">${js_dictionary[1]}</p>`);
        load_more_button.style.display = "none";
      }
      posts_loading.style.display = "none";
    },
    error: function () {
      posts_loading.innerHTML = `<p class="text-center" style="width: 100%">${js_dictionary[2]}</p>`;
    }
  });
}
if (load_more_button) {
  load_more_button.addEventListener("click", event => {
    event.preventDefault();
    console.log(currentPage);
    loadPosts(currentPage);
  });
}
if (posts_container) {
  loadPosts(currentPage);
}